import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { TopPage } from "./components/Pages/topPage";
import { LaytestPage } from './components/Pages/laytestPage';
import { ArchivesPage } from './components/Pages/archivesPage';
import { ArchivesYearPage } from './components/Pages/archivesYearPage';
import { DetailPage } from './components/Pages/detailPage';


function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_DIR}>  
    <Routes>
          <Route path={`/`} element={
            <TopPage />
          } />
          <Route path={`/laytest`} element={
            <LaytestPage />
          } />
           <Route path={`/archives`} element={
            <ArchivesPage />
          } />
             <Route path={`/archives/:year`} element={
            <ArchivesYearPage />
          } />
          <Route path={`/numbers/:number`} element={
            <DetailPage />
          } />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
