import React, { useState } from "react";
import { ArchivesEraView } from "../Views/archivesEraView";


export const ArchivesPage = () => {

    const currentYear = new Date().getFullYear()
    const reiwaStartYear = 2019;
    const [reiwaList, setReiwaList] = useState([{name:'元年', year:2019}])
    const [selectEra, setSelectEra] = useState(0)

    if(currentYear >= reiwaStartYear ){
        const reiwaYear = currentYear - reiwaStartYear + 1
        let i
        for(i = reiwaYear / 10; i < 0; i--){
            setReiwaList(reiwaList.push({name:`${i*10}年代`, year: 2019 +(i*10)}))
        } 
    }else{}


    return (
        <>
       
            <div className="contentes">
                <div className="archive--inner">
                    <div className="breadcrumb">
                        <a href="./index.html">TOP</a> &raquo;
                        <span>アーカイブ（年代検索）</span>
                    </div>
                    <nav className="archive">
                        <ul>
                            <li><a onClick={()=>{setSelectEra(1955)}}>昭和30年代</a></li>
                            <li><a onClick={()=>{setSelectEra(1965)}}>昭和40年代</a></li>
                            <li><a onClick={()=>{setSelectEra(1975)}}>昭和50年代</a></li>
                            <li><a onClick={()=>{setSelectEra(1985)}}>昭和60年代</a></li>
                            <li><a onClick={()=>{setSelectEra(1988)}}>平成元年代</a></li>
                            <li><a onClick={()=>{setSelectEra(1998)}}>平成10年代</a></li>
                            <li><a onClick={()=>{setSelectEra(2008)}}>平成20年代</a></li>
                            <li><a onClick={()=>{setSelectEra(2018)}}>平成30年代</a></li>
                            {
                                reiwaList.map((r,i) => {
                                    return(
                                        <li key={i}><a onClick={()=>{setSelectEra(r.year)}}>令和{r.name}</a></li>
                                    )
                                })
                            }
                        </ul>
                    </nav>
                </div>
            </div>
            
            {
                selectEra > 0 && 
                <ArchivesEraView {...{selectEra, setSelectEra}}></ArchivesEraView>
            }
        </>
    )

}