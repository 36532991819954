import React,{useState, Suspense, useEffect} from "react";

import styled from 'styled-components';
import { fetchView } from "../../data/fetchArchives";
import { Loader } from "src/components/Parts/loaderParts";
import { Useable } from 'src/utils/Useable';
import { LaytestView } from "../Views/laytestView";
import { Link, useParams } from "react-router-dom";
import { ArchivesDetailView } from "../Views/archivesDetailView";

const StyledDiv = styled.div`
  --mr: 1%;
`;

export const DetailPage = () => {

    const { number } = useParams()

    const [archive, setArchive] = useState(0)

    useEffect(()=>{
        setArchive(
            new Useable( fetchView(number))
        )
    }, [])
    
    return (
        <div className="contentes">
        <div className="latest--inner">
            <div className="breadcrumb">
                <Link to={`/`}>TOP</Link> &raquo;
                <span>第{number}号</span>
            </div>

            {
                archive !== 0 &&
                <Suspense fallback={<Loader></Loader>}>
                    <ArchivesDetailView {...{data:archive}}></ArchivesDetailView>
                </Suspense>
            }
        </div>
    
    </div>
    )
}