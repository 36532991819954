import parse from 'html-react-parser';

export const textToLink = (text: string) => {
    // 正規表現でURLを抽出
    const regexp_url = /(https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+)/g;
    let linkedComment = text.replace(regexp_url, '<a href="$1">$1</a>');
  
    // 正規表現で#を抽出
    const regexp_hash = /#+([a-zA-Z0-9亜-熙ぁ-んァ-ヶー-龥朗-鶴.\-_]+)/g;
    linkedComment = linkedComment.replace(
      regexp_hash,
      '<a href="/search?q=$1&type=hash">#$1</a>'
    );
  
    // 正規表現で@を抽出
    const regexp_at = /@+([a-zA-Z0-9亜-熙ぁ-んァ-ヶー-龥朗-鶴.\-_]+)/g;
    linkedComment = linkedComment.replace(
      regexp_at,
      '<a href="/search?q=$1&type=at">@$1</a>'
    );

    return linkedComment;
  };

export const textToBr = (text: string) => {

  // <li>タグを削除
  const withoutLiTags = text.replace(/<li>/g, '').replace(/<\/li>/g, '<br/>');

  // <li>タグが削除されたテキストを改行コードに変換
  const withNewLines = withoutLiTags.replace(/<br\s*\/?>/gi, '\n');

  console.log(withoutLiTags)
  return parse(withNewLines.replace(/(\r\n|\r|\n)/g, '<br>'));
  
}

export function numFormat(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}