import React,{useState, Suspense, useEffect} from "react";
import { Loader } from "src/components/Parts/loaderParts";
import { Useable } from 'src/utils/Useable';
import { LaytestView } from "./laytestView";
import { fetchEra } from 'src/data/fetchArchives'
import { convertToJapaneseEra, formattedYear } from "../../utils/dateTime";
import { Link } from "react-router-dom";
const ArchivesList = (props) => {
    const {respons, data:archives} = props.data.getOrThrow();
    
    return (
        <>
        {
            respons === 'success' &&
            <ul>
                {
                    archives.map((archive,i) => {
                        return(
                            <li key={i}>
                                <Link to={`/archives/${formattedYear(archive.issue_date)}`}>
                                <figure>
                                    <img src={`${process.env.REACT_APP_PDF}/${archive.thumb}`}/>
                                    <figcaption> {convertToJapaneseEra(archive.issue_date)} </figcaption>
                                </figure>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        }
        {
            respons === 'error' &&
            <h1>エラーが発生しました</h1>
        }
        </>
    )
}

export const ArchivesEraView = (props) => {

    const [archives, setArchives] = useState(0)

    useEffect(()=>{
        setArchives(
            new Useable( fetchEra(props.selectEra) )
        )
    }, [])
    

    return(
        <>
            <div className="archive_modal">
                <h1>{convertToJapaneseEra(`${props.selectEra}-06-01`)}代</h1>
                <div className="archive_modal--wrap">
                    <p>年をクリックすると各号が表示れます。</p>
                    {
                    archives !== 0 &&
                    <Suspense fallback={<Loader></Loader>}>
                        <ArchivesList {...{data:archives}}></ArchivesList>
                    </Suspense>
    
                    }
                    <button className="archive_modal--close" onClick={() => {props.setSelectEra(0)}}>閉じる</button>
                </div>
                <button className="archive_modal--close-icon" onClick={() => {props.setSelectEra(0)}}></button>
                </div>
        </>
    )
}