import axios from 'axios'

/**
 * Areas API
 * @returns 
 */
export const fetchLaytest = async () => {
    axios.defaults.withCredentials = true;
    
    let requestUrl
    requestUrl = `${process.env.REACT_APP_API}/archives/laytest.json` 
            
    try {
        const res = await axios.get(requestUrl);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }

};

export const fetchEra = async (year:number) => {
  axios.defaults.withCredentials = true;
  
  let requestUrl
  requestUrl = `${process.env.REACT_APP_API}/archives/era.json?y=${year}` 
            
  try {
      const res = await axios.get(requestUrl);

      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
  
};

export const fetchYear = async (year:number) => {
  axios.defaults.withCredentials = true;
  
  let requestUrl
  requestUrl = `${process.env.REACT_APP_API}/archives/search.json?y=${year}` 
            
  try {
      const res = await axios.get(requestUrl);

      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
  
};


export const fetchView = async (number:number) => {
  axios.defaults.withCredentials = true;
  
  let requestUrl
  requestUrl = `${process.env.REACT_APP_API}/archives/view.json?number=${number}` 
            
  try {
      const res = await axios.get(requestUrl);

      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
  
};