import React,{useState, Suspense, useEffect} from "react";
import { Link, useParams } from "react-router-dom";

import { fetchLaytest } from 'src/data/fetchArchives'
import { Loader } from "src/components/Parts/loaderParts";
import { Useable } from 'src/utils/Useable';
import { fetchYear } from 'src/data/fetchArchives'
import { ArchivesYearView } from "../Views/archivesYearView";
import { convertToJapaneseEra } from "../../utils/dateTime";

export const ArchivesYearPage = ()  => {

    const { year } = useParams()

    const [archives, setArchives] = useState(0)
    const [selectYear, setSelectYear] = useState(parseInt(year))
    const [jpYear, setJpYear] = useState(convertToJapaneseEra(year))

    useEffect(() => {
        
        setJpYear(convertToJapaneseEra(String(selectYear)))
        setArchives(new Useable(fetchYear(selectYear)))

    },[selectYear]) 

    const prevYear = () => {
        setSelectYear(selectYear-1)
    }

    const nextYear = () => {
        setSelectYear(selectYear+1)
    }


    return(
        <div className="contentes">
            <div className="archive--inner">
                <div className="breadcrumb">
                    <Link to={`/`}>TOP</Link> &raquo;
                    <Link to={`/archives`}>アーカイブ</Link> &raquo;
                    <span>{jpYear}</span>
                </div>
               
                {
                archives !== 0 &&
                <Suspense fallback={<Loader></Loader>}>
                    <ArchivesYearView {...{data:archives, prevYear, nextYear}}> </ArchivesYearView>
                </Suspense>
                }
                
            </div>
            
        </div>
    )




}