import React from "react";
import { useParams } from "react-router-dom";
import { convertToJapaneseEraMonth } from "../../utils/dateTime";
import { textToBr } from "../../utils/textUtils";
import styled from 'styled-components';

const StyledDiv = styled.div`
  --mr: 1%;
`;


export const ArchivesDetailView = (props) => {

    const {respons, data:archive} = props.data.getOrThrow();

    return (
        <>
         {
            respons === 'success' &&
            <StyledDiv className="flex_wrap--mrable col2 sp-col1">
                <section className="item">
                    <h1 className="headlineA"><b>{convertToJapaneseEraMonth(archive.issue_date)}</b> 第{archive.number}号</h1>
                    <a href={`${process.env.REACT_APP_PDF}/${archive.pdf}`} target="_blank">
                    <figure className="thumb"> 
                        <img src={`${process.env.REACT_APP_PDF}/${archive.thumb}`}/>
                    </figure>
                    </a>
                </section>
                <section className="item">
                    <h1 className="headlineB"><b>目次</b></h1>
                    <p className="magazinIndex">
                        {textToBr(archive.description)}
                    </p>
                    <a href={`${process.env.REACT_APP_PDF}/${archive.pdf}`} target="_blank" className="button">
                        閲覧する
                        <span>PDFリーダーで表示されます</span>
                    </a>  
                </section>
            </StyledDiv>
        }
        </>

    )

}