import React,{useState, Suspense, useEffect} from "react";

import styled from 'styled-components';
import { fetchLaytest } from 'src/data/fetchArchives'
import { Loader } from "src/components/Parts/loaderParts";
import { Useable } from 'src/utils/Useable';
import { LaytestView } from "../Views/laytestView";

const StyledDiv = styled.div`
  --mr: 1%;
`;

export const LaytestPage = () => {
    
    const [laytest, setLaytest] = useState(0)

    useEffect(()=>{
        setLaytest(
            new Useable(
                fetchLaytest()
                )
        )
    }, [])
    
    return (
        <div className="contentes">
        <div className="latest--inner">
            <div className="breadcrumb">
                <a href="./index.html">TOP</a> &raquo;
                <span>最新号</span>
            </div>
    
            <StyledDiv className="flex_wrap--mrable col2 sp-col1">
                {
                    laytest !== 0 &&
                    <Suspense fallback={<Loader></Loader>}>
                        <LaytestView {...{data:laytest}}></LaytestView>
                    </Suspense>
    
                }
            </StyledDiv>
    
        </div>
    
    </div>
    )
}