import React from "react";
import { formattedYearMonth } from "../../utils/dateTime";
import { textToBr } from "../../utils/textUtils";
import { Link } from "react-router-dom";

export const LaytestView = (props) => {

    const {respons, data:archive} = props.data.getOrThrow();

    return(
        <>
        {
            respons === 'success' &&
            <>
            <section className="item">
                <h1 className="headlineA"><b>最新号</b>{formattedYearMonth(archive.issue_date)}号</h1>
                <a href={`${process.env.REACT_APP_PDF}/${archive.pdf}`} target="_blank">
                <figure className="thumb"> 
                    <img src={`${process.env.REACT_APP_PDF}/${archive.thumb}`}/>
                </figure>
                </a>
            </section>
            <section className="item">
                <h1 className="headlineB"><b>目次</b></h1>
                <p className="magazinIndex">
                   {textToBr(archive.description)}
                </p>
                <ul className="topMenu">
                    <li>
                        <a href={`${process.env.REACT_APP_PDF}/${archive.pdf}`} target="_blank" className="button">
                            閲覧する
                            <span>PDFリーダーで表示されます</span>
                        </a>  
                    </li>
                    <li>
                        <Link  to={`/archives`} className="button">
                            アーカイブ
                            <span>年代から検索できます</span>
                        </Link>  
                    </li>
                </ul>
            </section>
            </>
        }
        </>
    )
}