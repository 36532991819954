import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { fetchLaytest } from 'src/data/fetchArchives'
import { Link } from "react-router-dom";

const StyledDiv = styled.div`
  --mr: 1%;
`;

export const TopPage = () => {
    
    const [thumb, setThumb] = useState(0)
    
    useEffect(() => {
        fetchLaytest().then((res) => {
            if(res.respons === 'success') setThumb(res.data.thumb)
        })
    },[])    
    
    return (
        <>
           <div className="contentes">
                <div className="main--inner">
                    <StyledDiv className="flex_wrap--mrable col2 sp-col1" >
                        <div className="item">
                            {   
                                thumb !== 0 &&
                                <figure><img src={`${process.env.REACT_APP_PDF}/${thumb}`}/></figure>
                            }
                        </div>
                        <div className="item">
                            <ul className="topMenu">
                            <li>
                                <Link to={`/laytest`} className="button">
                                    最新号
                                    <span>広報ひゅうがの最新号がご覧になれます</span>
                                </Link>  
                            </li>
                            <li>
                                <Link to={`/archives`} className="button">
                                アーカイブ
                                <span>年代から検索できます</span>
                                </Link>  
                            </li>
                            <li>
                                <a href="/contents/archive_view.html" className="button">
                                ご利用にあたって
                                </a>  
                            </li>
                            <li><a href="http://hyuga.digital-archives.jp/togo/years.php" target="_blank"><img src="./images/togo.jpg"/></a> </li>
                            </ul>
                        </div>
                    </StyledDiv>
                </div>
                </div>
        </>
    )
}