
import React,{useState, Suspense, useEffect} from "react";
import { Loader } from "src/components/Parts/loaderParts";
import { Useable } from 'src/utils/Useable';
import styled from 'styled-components';
import { convertToJapaneseEraMonth } from "../../utils/dateTime";
import { fetchView } from "../../data/fetchArchives";
import { ArchivesDetailView } from "./archivesDetailView";
const StyledUl = styled.ul`
  --mr: 2%;
`;

const DetailView = (props) => {
    
    const [archive, setArchive] = useState(0)
    useEffect(() => {
        setArchive(
            new Useable( fetchView( props.number ))
        )
    },[props.number]);

    return (
        <>
        <div className="mb-30">
         <button className="archive_modal--close" onClick={() => {props.setNumber(0)}}>閉じる</button>
         </div>
        {archive !== 0 && 
            <Suspense fallback={<Loader></Loader>}>
                <ArchivesDetailView {...{data:archive}}></ArchivesDetailView>
            </Suspense>
        }
        </>
    )

}

export const ArchivesYearView = (props) => {
    const {respons, data:archives} = props.data.getOrThrow();

    const [number, setNumber] = useState(0)

    return (
        <>
        {
            number === 0 &&
            <>
            <nav className="nextPrev">
                <button className="prev" onClick={() => {  props.prevYear() }} >前の年</button>
                <button onClick={() => {  props.nextYear() }} className="next">次の年</button>
            </nav>
            <StyledUl className="flex_wrap--mrable  col3 sp-col1">
            {
                respons === 'success' &&
                archives.map((archive,i) => {
                    return (
                    <li className="item" key={i}>
                        <div className="magazine" onClick={() => {setNumber(archive.number)}}>
                            <h2>{convertToJapaneseEraMonth(archive.issue_date)} 第{archive.number}号</h2>
                            <figure >
                                <img src={`${process.env.REACT_APP_PDF}/${archive.thumb}`}/>
                            </figure>
                            
                        </div>
                    </li>
                    )
                })
            }
            </StyledUl>
             <nav className="nextPrev">
             <button className="prev" onClick={() => {  props.prevYear() }} >前の年</button>
                <button onClick={() => {  props.nextYear() }} className="next">次の年</button>
            </nav>
            </>
        }
        {
            number > 0 && 
            <DetailView {...{number, setNumber}}></DetailView>
            
        }
        </>
    )
}