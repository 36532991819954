
export const formattedYearMonth = (dateString) => {
    
    const date = new Date(dateString);

    return `${date.getFullYear()}年${date.getMonth() + 1}月`

}

export const formattedYear = (dateString) => {
    
  const date = new Date(dateString);

  return date.getFullYear()

}

export　function convertToJapaneseEra(dateString) {
    const inputDate = new Date(dateString);
    const year = inputDate.getFullYear();
    let eraName = '';
    let eraYear = 0;
  
    // 令和は2019年5月1日から始まった
    if (year >= 2019 && inputDate >= new Date('2019-05-01')) {
      eraName = '令和';
      eraYear = year - 2019 + 1;
    }
    // 平成は1989年1月8日から2019年4月30日まで
    else if (year >= 1989) {
      eraName = '平成';
      eraYear = year - 1989 + 1;
    }
    // 昭和は1926年12月25日から1989年1月7日まで
    else if (year >= 1926) {
      eraName = '昭和';
      eraYear = year - 1926 + 1;
    }
    // それ以前は不明
    else {
      eraName = '不明';
      eraYear = 0;
    }
  
    return `${eraName}${eraYear}年`;
  }

  export　function convertToJapaneseEraMonth(dateString) {
    const inputDate = new Date(dateString);
    const year = inputDate.getFullYear();
    let eraName = '';
    let eraYear = 0;
  
    // 令和は2019年5月1日から始まった
    if (year >= 2019 && inputDate >= new Date('2019-05-01')) {
      eraName = '令和';
      eraYear = year - 2019 + 1;
    }
    // 平成は1989年1月8日から2019年4月30日まで
    else if (year >= 1989) {
      eraName = '平成';
      eraYear = year - 1989 + 1;
    }
    // 昭和は1926年12月25日から1989年1月7日まで
    else if (year >= 1926) {
      eraName = '昭和';
      eraYear = year - 1926 + 1;
    }
    // それ以前は不明
    else {
      eraName = '不明';
      eraYear = 0;
    }
  
    return `${eraName}${eraYear}年${inputDate.getMonth() + 1}月`;
  }